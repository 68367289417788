.technology {
    #main-component {
        .container {
            .top-zone {
                max-height: 29rem;
                position: relative;
                overflow: hidden;
                margin-bottom: 5rem;
                margin-top: 4rem;
                box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.35);

                .image-container {
                    @include respond(768) {
                        height: 29rem;
                    }

                    img {
                        width: 100%;

                        @include respond(768) {
                            object-fit: cover;
                            height: 100%;
                        }
                    }

                    .hover-shape {
                        background: rgba(0, 94, 157, 0.44);
                    }
                }

                .text {
                    position: absolute;
                    top: 50%;
                    left: 4rem;
                    transform: translateY(-50%);
                    max-width: 61rem;

                    @include respond(768) {
                        max-width: 39rem;
                    }

                    h1 {
                        font-size: 6.8rem;
                        font-weight: 400;
                        color: #fff;
                        border: 0;
                        margin-bottom: 2rem;
                        padding-bottom: 0;

                        @include respond(992) {
                            font-size: 5.8rem;
                        }
                    }

                    p {
                        font-size: 2.3rem;
                        font-weight: 400;
                        line-height: 2.7rem;
                        color: #fff;
                    }
                }
            }

            .category-text {
                margin-bottom: 8rem;

                p {
                    font-size: 1.8rem;
                    font-weight: 400;
                    line-height: 3rem;
                    color: #000;
                }
            }

            .technology-items {
                >.row {
                    margin: 0;
                    gap: 5%;

                    @include respond(992) {
                        gap: 0;
                    }

                    .item {
                        margin-bottom: 0;
                        padding: 1.5rem;
                        max-width: 30%;
                        margin-bottom: 8rem;
                        border-radius: 6px;
                        padding-left: 0;
                        padding-right: 0;

                        @include respond(992) {
                            flex: 0 50%;
                            max-width: 50%;
                        }

                        @include respond(768) {
                            flex: 0 100%;
                            max-width: 100%;
                        }

                        .item-body {
                            padding: 0;

                            @include respond(992) {
                                padding: 1.5rem;
                            }

                            h5 {
                                font-size: 2.7rem;
                                line-height: 3.4rem;
                                font-weight: 400;
                                color: #fff;
                                margin-bottom: 3rem;
                                min-height: 7.5rem;
                                padding: 4px 15px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 5rem;
                            }

                            p {
                                font-size: 1.8rem;
                                font-weight: 400;
                                line-height: 3rem;
                                color: #000;

                                span {
                                    display: block;
                                    margin-top: 4rem;
                                }
                            }
                        }
                    }
                }
            }

            .our-solutions {
                h2 {
                    font-size: 5.4rem;
                    line-height: 7rem;
                    font-weight: 400;
                    color: $primary;
                    margin-bottom: 2rem;

                    +ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 5rem;
                        display: flex;
                        gap: 1rem;
                        justify-content: center;

                        li {
                            font-weight: 700;
                            font-size: 2.5rem;
                            line-height: 3.2rem;
                            color: $primary;

                            &.vividViolet {
                                color: $vividViolet;
                            }
                        }
                    }
                }

                .items {
                    padding: 0px 15px;

                    >.row {
                        &:nth-child(odd) {
                            >div {
                                background-color: #F4F5F5;
                            }
                        }

                        >div {
                            padding: 4rem 6rem;
                            text-align: center;

                            &.parent_solution_7 {
                                .item-title {
                                    color: $vividViolet;
                                }
                            }

                            &.parent_solution_8 {
                                .item-title {
                                    color: $primary;
                                }
                            }

                            .item-title {
                                font-size: 2.7rem;
                                font-weight: 400;
                                line-height: 4.2rem;
                            }

                            .item-img {
                                text-align: center;
                                margin: 1rem 0;
                            }

                            .item-text {
                                font-size: 2.1rem;
                                font-weight: 400;
                                line-height: 3.1rem;
                                color: #000;
                            }
                        }
                    }
                }

                .gdt-btn {
                    font-size: 3.1rem;
                    margin: 5rem 0;
                    display: flex;
                    width: fit-content;
                    padding: 13px 30px;
                }
            }
        }
    }
}