@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}  
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}  
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
  -webkit-animation: 	#{$str};
  -moz-animation: 		#{$str};
  -ms-animation: 		#{$str};
  -o-animation: 		#{$str};
  animation: 			#{$str};      
}

@include keyframes(swing) {
	15% {transform: translateX(5px);}
	30% {transform: translateX(-5px);}
	50% {transform: translateX(3px);}
	65% {transform: translateX(-3px);}
	80% {transform: translateX(2px);}
	100%{transform: translateX(0);}
}

@mixin transition($time) {
  -webkit-transition: 	all ease-in-out #{$time};
  -moz-transition: 		all ease-in-out #{$time};
  -ms-transition: 		all ease-in-out #{$time};
  -o-transition: 		all ease-in-out #{$time};
  transition: 			all ease-in-out #{$time};      
}
@mixin transitionLinear($time) {
  -webkit-transition: 	all linear #{$time};
  -moz-transition: 		all linear #{$time};
  -ms-transition: 		all linear #{$time};
  -o-transition: 		all linear #{$time};
  transition: 			all linear #{$time};      
}


.theguy a:hover{
   @include animation('swing 1s');
}

#offcanvas {
	@include transition(.5s);
	.container {
		@include transition(1s);
	}
}

.menu>li {
	a{
		@include transition(.5s);
		ul{
			@include transition(.5s);

		}
	}
}



#facebook .container-fluid a,
#facebook .container-fluid a i{
	@include transition(.5s);
}