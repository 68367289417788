//=======================================================================
// UNIVERSAL
//=======================================================================

html {
    // overflow-y: scroll; // scrollbar fix (prevent ugly left pull)
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;

    @include respond(1600) {
        font-size: 61%;
    }

    @include respond(1400) {
        font-size: 60%;
    }

    @include respond(1200) {
        font-size: 59%;
    }

    @include respond(992) {
        font-size: 58%;
    }

    @include respond(468) {
        font-size: 57%;
    }
}

::selection {
    background-color: $primary;
    color: #fff;
}

a {
    &:hover {
        @include transition(all .3s ease);
    }
}

section {
    outline: none $i;
}

body {
    overflow-x: hidden;
    font-size: 18px;
    color: #171812;

    .fa,
    .fas,
    .far {
        font-family: 'Font Awesome 5 Free'$i;
    }

    &.rtl,
    &.ltr {
        * {
            font-family: 'Kohinoor Devanagari', sans-serif $i;
        }

        .fa,
        .fas,
        .far {
            font-family: 'Font Awesome 5 Free'$i;
        }

        .fab {
            font-family: 'Font Awesome 5 Brands'$i;
        }
    }

    &.fluid-body {
        #main-component {
            >.container {
                max-width: 100%;
                padding: 0;

                .normal-container {
                    width: 100%;
                    max-width: 1140px;
                    padding: 0px 15px;
                    margin: 0 auto;
                }
            }
        }
    }
}

@mixin transition($params) {
    -webkit-transition: $params;
    -moz-transition: $params;
    -ms-transition: $params;
    -o-transition: $params;
    transition: $params;
}

h2 {
    font-size: 4.2rem;
    color: $primary;
    margin-bottom: 5rem;
    text-align: initial;
    // letter-spacing: 4px;
    // text-transform: uppercase;

    @include respond(768) {
        font-size: 3.2rem $i;
    }

    @include respond(468) {
        font-size: 2.8rem $i;
    }
}

h1 {
    @include respond(768) {
        font-size: 3.2rem $i;
    }

    @include respond(468) {
        font-size: 2.8rem $i;
    }
}

p {
    @include respond(768) {
        font-size: 2rem $i;
    }
}

.top-image {
    @include respond(768) {
        height: 20rem $i;
    }
}

.gdt-btn,
.btn_download>a {
    color: #fff $i;
    font-size: 2.5rem;
    font-weight: 500;
    background: linear-gradient(90deg, rgba(0, 93, 157, 1) 0%, rgba(126, 42, 144, 1) 100%);
    padding: 16px 26px;
    border-radius: 0;
}

.breadcrumb {
    margin-top: 5rem;
    font-size: 1.7rem;

    li {
        .divider {
            width: 1rem;
            display: inline-flex;
        }
    }
}

$transition300: all 300ms ease-in-out $i;

li:hover>a,
li>a,
#logo,
.fixed #logo,
.preloader,
#logo img,
.togglers h3:before,
ul.nav.menu>li a:after,
#ituran,
.id-101 .services .col-sm-4 a,
.border-image,
.product-info,
.hover-cont,
.relateditems a img {
    @include transition($transition300);
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}

.container {
    width: 100%;
    max-width: 140rem;
}

.hover-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(126, 42, 144, 1) 0%, rgba(0, 93, 157, 1) 100%);
    opacity: 0.5;
}

.read-more.og {
    width: 100%;
    max-width: 30rem;
    display: block;
    font-size: 2.6rem;
    text-transform: uppercase;
    color: #fff;
    background-color: $primary;
    text-shadow: 0px 4px #F15A24;
    border-bottom: 4px solid #F15A24;
    letter-spacing: 3px;
    padding: 1rem;
    text-align: center;
    margin: auto;

    @include respond(1400) {
        font-size: 2rem;
    }

    &.reverse {
        background-color: #fff;
        color: $primary;
        text-shadow: 0px 2px #F15A24;

        @include respond(1400) {
            text-shadow: 0 1px #f15a24;
        }
    }
}

/*=============================================
=            Youtube Video            =
=============================================*/

.video_wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100%;

    .videoIframe,
    .videoIframe-small {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .videoPoster,
    .videoPoster-small {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        opacity: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 40%;
        -webkit-transition: opacity 800ms, height 0s;
        -moz-transition: opacity 800ms, height 0s;
        transition: opacity 800ms, height 0s;
        -webkit-transition-delay: 0s, 0s;
        -moz-transition-delay: 0s, 0s;
        transition-delay: 0s, 0s;
        background-image: url('../images/hp_video_image.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:before {
            // content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 23rem;
            height: 23rem;
            color: #000;
            font-size: 23rem;
            // background: url('../img/youtube_play.png');
        }

        &.small {
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 95px;
                height: 94px;
                background: url('../images/youtube_button.png');
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &.videoWrapperActive {
        .videoPoster {
            opacity: 0;
            height: 0;
            -webkit-transition-delay: 0s, 800ms;
            -moz-transition-delay: 0s, 800ms;
            transition-delay: 0s, 800ms;
        }
    }
}

/*=====  End of Youtube Video  ======*/

.ltr {
    #offcanvas {
        .nav.menu {
            li {
                a {
                    display: block;
                    text-align: left;
                }

                i {
                    left: auto;
                    right: 0;
                }

                &.parent {
                    ul {
                        li {
                            a {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

#offcanvas .nav.menu li:not(.deeper) a {
    display: block;
}

a {
    color: $primary;

    &:hover {
        text-decoration: none;
        // color: #000 $i;
        // background-color: transparent $i;
    }
}

.back-to-top {
    position: fixed;
    right: 0px;
    bottom: 20rem;
    display: none;
}

// Search module

.search {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 5rem);
    display: none;

    form {
        height: 100%;

        .search-query {
            height: 100%;
            display: block;
            width: 100%;
            color: #555555;
            background-color: #ffffff;
            border: 1px solid #aaaaaa;
            transition: border linear .2s, box-shadow linear .2s;
            padding: 4px 5px;
        }
    }
}

.search-icon {
    width: 20px;
    height: 17px;
    cursor: pointer;
    display: flex;
}

// EO Search module

.side-contact-btn {
    position: fixed;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    width: 11rem;
    height: 11rem;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    line-height: 3rem;
    font-size: 23px;
    justify-content: center;

    @include respond(1900) {
        width: 9rem;
        height: 9rem;
        line-height: 2rem;
        font-size: 21px;
        left: 7px;
    }

    @include respond(1600) {
        bottom: 7px;
        transform: none;
        top: auto;
    }

    @include respond(768) {
        width: 100%;
        height: 4rem;
        text-align: center;
        left: 0;
        bottom: 0;
        border-radius: 0;
        display: flex;
        justify-content: center;
    }
}

.mod-languages {
    position: fixed;
    z-index: 99999;
    top: 48px;
    right: 10%;

    @include respond(1900) {
        right: 2%;
    }

    @include respond(1600) {
        top: 38px;
        right: 74%;
    }

    @include respond(1400) {
        top: 28px;
    }

    @include respond(768) {
        right: 44%;
    }

    @include respond(468) {
        top: 24px;
    }

    .dropdown-toggle {
        font-size: 18px;
        color: #005d9d;
        padding: 0;
    }

    .dropdown-menu {
        box-shadow: 3px 4px 3px 0 #00000042;
        border: 0px;
        padding: 30px;
        min-width: 240px;
        flex-wrap: wrap;
        font-size: 18px;
        left: -90px $i;
        line-height: 40px;

        &.show {
            display: flex;
        }
    }
}

.view-article {
    #main-component {
        >.container {
            margin-top: 5rem;
            margin-bottom: 10rem;

            h1 {
                text-align: initial;
                font-size: 4.7rem;
                font-weight: 400;
                color: $primary;
                line-height: 6.1rem;
                margin-bottom: 3rem;
                border-bottom: 1px solid #669ec4;
                padding-bottom: 2rem;
            }

            .text {

                p,
                li {
                    font-size: 1.8rem;
                    font-weight: 400;
                    color: #000;
                    line-height: 3rem;

                    img {
                        margin-left: 0 $i;
                        margin-right: 0 $i;
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;
                    flex-wrap: wrap;
                    display: flex;

                    li {
                        display: flex;
                        flex: 100%;
                        align-items: center;
                        gap: 1rem;
                        flex-wrap: wrap;

                        &::before {
                            content: '';
                            background-image: url('../images/list_icon.svg');
                            width: 14px;
                            height: 15px;
                            position: relative;
                        }

                        ul {
                            padding-left: 3rem;
                            margin-bottom: 2rem;

                            li {
                                &::before {
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.nopadding {
    padding: 0;
}

.margin20 {
    margin-bottom: 20px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.center-text {
    text-align: center;
}

.mobile-menu {
    text-align: right;
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999999999;
    background: #fff;
    width: 100vw;
    height: 100vh;
    opacity: 1;

    .progress {
        height: 6px;
        margin-top: 0;
        background-color: rgba(181, 30, 53, .2);

        .indeterminate {
            background-color: $primary;
        }
    }

    .preloader-wrapper.big {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        position: absolute;
        top: calc(50% - 125px);
        left: calc(50% - 125px);
    }
}

.loaded {
    .preloader {
        opacity: 0;
        z-index: -1;
    }
}

.mobile-menu {
    text-align: right;
    height: 100%;
    // background: #fff;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;

    .con {
        width: auto;
        margin: 0 auto;
        -webkit-transition: all .7s ease;
        -moz-transition: all .7s ease;
        -ms-transition: all .7s ease;
        -o-transition: all .7s ease;
        transition: all .7s ease;
        cursor: pointer;
        display: inline-block;

        .bar {
            display: block;
            height: 5px;
            width: 50px;
            background: $primary;
            margin: 10px auto;
            border-radius: 10px;
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
        }

        .middle {
            margin: 0 auto;
        }

        &.clicked {
            .top {
                -webkit-transform: translateY(15px) rotateZ(45deg);
                -moz-transform: translateY(15px) rotateZ(45deg);
                -ms-transform: translateY(15px) rotateZ(45deg);
                -o-transform: translateY(15px) rotateZ(45deg);
                transform: translateY(15px) rotateZ(45deg);
            }

            .bottom {
                -webkit-transform: translateY(-15px) rotateZ(-45deg);
                -moz-transform: translateY(-15px) rotateZ(-45deg);
                -ms-transform: translateY(-15px) rotateZ(-45deg);
                -o-transform: translateY(-15px) rotateZ(-45deg);
                transform: translateY(-15px) rotateZ(-45deg);
            }

            .middle {
                width: 0;
            }
        }
    }
}

//Form Sent
.fox-container {
    margin-bottom: 5rem;

    .alert.alert-success {
        color: #fff $i;
        background-color: transparent $i;
        border: 0 $i;
        border-bottom: 3px solid #fff $i;
        text-align: center;
        margin: 0 $i;
        padding: 0 $i;
        padding-bottom: 46px $i;

        .close {
            display: none;
        }

        .fox-messages {
            text-shadow: none;
            color: #fff;

            dl {
                dt {
                    font-size: 48px;
                    font-weight: 300;
                    margin-top: 40px;
                    line-height: 54px;
                    margin-bottom: 13px;
                }

                dd {
                    font-size: 18px;
                    font-weight: 300;
                }
            }
        }
    }

    form {
        .fox-row {
            display: flex;

            @include respond(768) {
                flex-wrap: wrap;
            }

            .fox-column {
                float: none;

                &.fox-column6 {
                    flex: 50%;
                    max-width: 50%;
                    width: 50% $i;

                    @include respond(768) {
                        flex: 100%;
                        max-width: 100%;
                        width: 100% $i;
                    }
                }

                .fox-item {
                    float: none;

                    h3 {
                        font-size: 2.3rem;
                        line-height: 3.3rem;
                        color: #000;
                        font-weight: 400;
                    }

                    .control-label,
                    .controls {
                        float: none $i;
                        display: flex $i;
                        flex: 100%;
                        width: 100% $i;
                        padding: 0 $i;

                        label {
                            font-size: 2.3rem;
                            line-height: 3.3rem;
                            color: $vividViolet;
                            font-weight: 400;
                        }

                        input,
                        textarea {
                            border-color: #bcbec0 $i;
                            border-radius: 0 $i;
                            width: 100% $i;
                        }

                        input {
                            height: 5.5rem $i;
                        }

                        textarea {
                            height: 22.6rem $i;
                        }

                        .submit-button {
                            color: #fff $i;
                            font-size: 2.3rem;
                            font-weight: 400;
                            background-image: none $i;
                            background: linear-gradient(90deg, rgba(0, 93, 157, 1) 0%, rgba(126, 42, 144, 1) 100%) $i;
                            padding: 10px 26px $i;
                            display: flex $i;
                            text-shadow: none $i;
                            box-shadow: none $i;
                            line-height: inherit $i;
                            border-radius: 0px $i;
                            border: 0 $i;
                            margin: 0 $i;
                            margin-right: 0 $i;
                            margin-left: auto $i;
                            min-width: 20rem;
                            justify-content: center;

                            .caption {
                                color: #fff $i;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*===============================
=            General            =
===============================*/

figure {
    margin: 0;
}

.grayscale img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    /* Firefox 10+, Firefox on Android */
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(100%);
    /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.grayscale:hover img {
    filter: none;
    /* Applies to FF + IE */
    -webkit-filter: grayscale(0);
}


/*=============================================
=            Our Solutions Grid            =
=============================================*/

.container-fluid {
    padding: 0;

    .our-solutions {
        h2 {
            font-size: 4.7rem;
            line-height: 6.1rem;
            font-weight: 400;
            color: $primary;
            margin-bottom: 2rem;
            text-align: center;

            +ul {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-bottom: 5rem;
                display: flex;
                gap: 1rem;
                justify-content: center;

                li {
                    font-weight: 700;
                    font-size: 2.2rem;
                    line-height: 2.8rem;

                    a {
                        color: $primary;
                    }

                    &.vividViolet {
                        a {
                            color: $vividViolet;
                        }
                    }
                }
            }
        }

        .items {
            padding: 0px 15px;

            >.row {
                justify-content: center;

                &:nth-child(odd) {
                    background-color: #F4F5F5;
                }

                >.row {
                    max-width: 140rem;
                    width: 100%;

                    >div {
                        padding: 4rem 6rem;
                        text-align: center;

                        @include respond(992) {
                            padding: 2rem;
                        }

                        &.parent_solution_7 {
                            .item-title {
                                color: $vividViolet;
                            }
                        }

                        &.parent_solution_8 {
                            .item-title {
                                color: $primary;
                            }
                        }

                        .item-title {
                            font-size: 2.3rem;
                            font-weight: 400;
                            line-height: 3rem;
                        }

                        .item-img {
                            text-align: center;
                            margin: 1rem 0;
                        }

                        .item-text {
                            font-size: 1.8rem;
                            font-weight: 400;
                            line-height: 3rem;
                            color: #000;
                        }
                    }
                }
            }
        }

        .gdt-btn {
            font-size: 2.7rem;
            margin: 5rem 0;
            display: flex;
            width: fit-content;
            padding: 13px 30px;
        }
    }
}

/*=====  End of Our Solutions Grid  ======*/



//Search Page
.view-search {
    .search {
        * {
            color: $primary;
        }

        .search-results {
            .result-title {
                font-size: 27px;
                text-decoration: underline;
            }

            .result-category {
                display: none;
            }
        }

        .pagination {
            ul {
                list-style: none;
                justify-content: center;
                display: flex;
                width: 100%;
                gap: 2rem;
                margin-top: 3rem;
                font-size: 2rem;
                font-weight: 500;
            }
        }
    }
}

.see-more {
    text-align: center;
    margin: auto;
    width: fit-content;
    color: #4D4D4D;
    position: relative;
    display: block;
    font-size: 3.5rem;
    margin-top: 5rem;
    margin-bottom: 4rem;

    &:after {
        position: relative;
        content: '';
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="15px"%3E%3Cpath fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M15.999,14.016 L0.006,0.980 L31.992,0.980 L15.999,14.016 Z"/%3E%3C/svg%3E');
        width: 32px;
        height: 15px;
        display: block;
        margin: auto;
        background-repeat: no-repeat;
    }
}

//Popup form
.popup-form {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.7);
    display: none;

    .form-main-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $primary;
        max-width: 1200px;
        padding: 50px;

        >i {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 40px;
            cursor: pointer;
            color: #fff;
        }

        h2,
        p {
            color: #fff;
        }

        h2 {
            font-size: 4rem;
            text-align: center;
            margin-bottom: 2rem;
            text-transform: uppercase;
        }

        p {
            font-size: 23px;
            line-height: 40px;
            margin-bottom: 2rem $i;
        }

        .fox-container {
            form {
                .fox-row {
                    display: flex;
                    flex-wrap: wrap;

                    .fox-column {
                        min-height: auto;
                        width: 100%;
                        max-width: 100%;
                        flex: 0 auto;
                        margin: 0;

                        .fox-item {
                            float: none;

                            .controls {
                                float: none;
                                width: 100% $i;

                                input,
                                textarea {
                                    width: 100% $i;
                                    height: 4.6rem $i;
                                    border: 0px;
                                    margin-bottom: 1rem $i;
                                    padding-left: 1rem $i;
                                    border-bottom: 4px solid #f15a24;
                                    font-size: 20px;
                                }

                                .checkbox {
                                    display: flex $i;
                                    align-items: center;
                                    width: 100% $i;
                                    color: #fff;
                                    font-size: 25px;

                                    input {
                                        &[type="checkbox"] {
                                            float: none;
                                            margin: 0;
                                            width: 40px $i;
                                            background: #fff;
                                            -webkit-appearance: none;
                                            appearance: none;
                                            position: relative;
                                            outline: none $i;
                                            margin-right: 2rem $i;
                                            margin-bottom: 0 $i;

                                            &:checked {
                                                &:after {
                                                    font-family: "Font Awesome 5 Free";
                                                    font-weight: 900;
                                                    content: "\f00c";
                                                    display: inline-block;
                                                    font-style: normal;
                                                    font-variant: normal;
                                                    text-rendering: auto;
                                                    -webkit-font-smoothing: antialiased;
                                                    font-size: 26px;
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(-50%, -50%);
                                                    color: $primary;
                                                }
                                            }
                                        }
                                    }
                                }

                                textarea {
                                    padding-top: 1rem;
                                    height: 15.8rem $i;
                                }

                                .btn {
                                    width: 100%;
                                    max-width: 100%;
                                    display: block;
                                    font-size: 2.8rem;
                                    text-transform: uppercase;
                                    color: $primary;
                                    background-color: #fff;
                                    border: 0;
                                    border-bottom: 4px solid #f15a24;
                                    padding: 0rem;
                                    text-align: center;
                                    margin: 1rem auto 0rem;
                                    border-radius: 0;
                                    height: 5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//EO Popup form

/*=====  End of General  ======*/