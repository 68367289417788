#footer {
    background: linear-gradient(90deg, rgba(0, 93, 157, 1) 0%, rgba(126, 42, 144, 1) 100%);

    .container {
        padding-top: 5rem;
        padding-bottom: 5rem;

        >.row {
            justify-content: space-between;

            @include respond(768) {
                justify-content: center;
                text-align: center;
            }

            >div {
                @include respond(992) {
                    flex: 0 25%;
                    max-width: fit-content;
                }

                @include respond(768) {
                    flex: 100%;
                    justify-content: center;
                    max-width: 100%;
                }

                &.logo {
                    margin-top: -3rem;

                    @include respond(992) {
                        margin-top: 0;
                    }

                    @include respond(768) {
                        margin-bottom: 2rem;
                    }
                }

                &.menu {
                    max-width: 40%;
                    padding-left: 7rem;

                    @include respond(992) {
                        max-width: initial;
                    }

                    @include respond(768) {
                        padding-left: 15px;
                        margin-bottom: 1rem;
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            flex: 100%;

                            a {
                                color: #fff;
                                font-size: 1.8rem;
                                line-height: 4rem;
                                font-weight: 500;
                            }

                            ul {
                                display: none;
                            }
                        }
                    }
                }

                &.cp {
                    flex-grow: 1;
                    font-size: 1.8rem;
                    color: #fff;
                    font-weight: 400;
                    line-height: 3.3rem;

                    @include respond(992) {
                        flex-grow: 0;
                    }

                    @include respond(768) {
                        margin-bottom: 1rem;
                    }
                }

                &.social {
                    @include respond(768) {
                        max-width: 100%;
                        flex-wrap: wrap;
                        display: flex;
                        gap: 1rem;
                        margin-top: 1rem;
                    }

                    a {
                        display: block;

                        img {
                            max-width: 3.4rem;
                        }

                        &:not(:last-child) {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}