#offcanvas {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    z-index: -3;
    top: 0;
    right: 0%;
    background: transparent;

    .container {
        max-width: 100%;
        right: -100%;
        // background: rgba(0, 102, 165, 0.8);
        width: 100%;

        >.col-md-10 {
            // padding: 0;

            .first-header {
                padding-top: 10px;
                padding-bottom: 12px;
                border-bottom: 1px solid $primary;

                >div {
                    >a {
                        width: 138px;
                        text-align: center;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 38px;
                        margin-left: -4px;
                        vertical-align: top;

                        &:first-child {
                            background-color: $primary;
                            margin-left: 1px;
                            margin-top: -1px;
                        }

                        &:last-child {
                            background-color: #000;
                        }
                    }

                    .mod-languages {
                        .lang-inline {
                            li {
                                position: relative;
                                display: inline-block;
                                vertical-align: top;
                                margin: 0;

                                a {
                                    color: $primary;
                                    font-size: 16px;
                                }

                                &.lang-active {
                                    margin-left: 12px;
                                    padding-left: 24px;

                                    a {
                                        color: #fff;
                                    }

                                    &:after {
                                        position: absolute;
                                        top: 7px;
                                        left: 0;
                                        content: '//';
                                        font-size: 16px;
                                        color: $primary;
                                        line-height: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .second-header {
                padding-top: 10px;

                >.ml-auto {
                    width: 100%;
                    margin-bottom: 10px;
                }

                .search {
                    >form {
                        position: relative;

                        button.button.btn.btn-primary,
                        input[type="image"] {
                            position: absolute;
                            top: 8px;
                            left: 10px;
                            z-index: 2;
                            border-radius: 20px;
                            height: 16px;
                            color: #fff;
                            width: 16px;
                            border: 0;
                            cursor: pointer;
                            text-indent: 99999px;
                            box-shadow: none;
                        }

                        input[name="searchword"] {
                            border: 1px solid $primary;
                            height: 31px;
                            border-radius: 20px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #fff;
                            background-color: $primary;
                            width: 100%;
                            padding-right: 17px;
                            padding-left: 40px;
                            outline: 0;
                            max-width: 100%;

                            &:active,
                            &:focus {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .close-btn {
            text-align: right;

            img {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 50%;
            }
        }

        ul.nav.menu>li {
            width: 100%;
            text-align: right;
            background: #fff;
            margin-bottom: 3px;
            border-bottom: 0px;
            border: 1px solid $primary;
            padding: 0 26px $i;
            // border-radius: 50px;

            &:after {
                display: none;
            }

            // &.active {
            // 	i.fa {
            // 		color: #fff $i;
            // 	}
            // }

            a {
                border-bottom: 0px;
                color: #383838;
                text-transform: uppercase;
                font-size: 23px;
                background-color: transparent;
                // border-radius: 50px;
                line-height: 30px;

                &:after {
                    display: none;
                }

                .fa-caret-down {
                    // display: none $i;
                }
            }

            ul {
                width: 100%;
                background-color: #fff;
                min-width: 100%;
                position: relative;
                margin: 0 auto;
                display: none;
                top: 0;
                padding: 0;
                box-shadow: none;

                &:before {
                    display: none;
                }

                li {
                    background: transparent;
                    border: 0;

                    &.li-image-parent {
                        display: block;
                        margin: 0;

                        >.nav-header {
                            display: none;
                        }

                        a {
                            display: block;

                            span {
                                margin-top: 8px;
                            }
                        }
                    }

                    &:before {
                        display: none;
                    }

                    a {
                        font-size: 18px $i;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &.opened {
        z-index: 99999;
        background: rgba(45, 44, 50, .8);
        opacity: 1;
        overflow: scroll;
        padding-bottom: 200px;
        padding-top: 20px;
        right: 0;
        margin-top: 80px;
        position: fixed;

        @include respond(468) {
            margin-top: 70px;
        }
    }

    &.fixed-opened {
        margin-top: 80px;

        @include respond(468) {
            margin-top: 70px;
        }
    }

    .nav.menu {
        li {
            position: relative;

            &.parent {
                .dropdown-menu {
                    position: relative;
                    background-color: transparent;
                    border: 0px;
                    box-shadow: none;
                    width: 100%;
                }
            }

            a {
                white-space: inherit;
                display: inline-block;
                padding: 8px 0px;
            }

            i {
                cursor: pointer;
                padding: 14px;
                position: absolute;
                left: 0;
                color: $primary;
                z-index: 0;
                top: 0;
            }
        }
    }
}