#header {
    height: 124px;

    @include respond(1600) {
        height: 100px;
    }

    @include respond(1400) {
        height: 80px;
    }

    @include respond(468) {
        height: 70px;
    }

    .fixed-top {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99999;
        margin: 0;
        height: 124px;
        padding: 0px;
        background: #fff;
        box-shadow: 0px 0px 87px 0px rgba(34, 34, 34, 0.39);

        @include respond(1600) {
            height: 100px;
        }

        @include respond(1400) {
            height: 80px;
        }

        @include respond(468) {
            height: 70px;
        }

        .mobile-menu .con {
            margin-top: 7px;
        }

        &.fixed {
            position: fixed;
            right: 0;
            top: 0;
            z-index: 999999;

            // .mobile-menu .con {
            // 	margin-top: 4px;
            // }
        }

        >.row {
            height: 100%;
            justify-content: space-between;
            align-items: center;
            max-width: 140rem;
            margin: auto;

            .menu {
                display: flex;
                align-items: center;

                ul.nav.menu {
                    padding: 0px;
                    text-align: center;

                    @include respond(1200) {
                        display: none;
                    }

                    >li {
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        padding: 0 16px 0 23px;

                        @include respond(1400) {
                            padding: 0 12px 0 12px;
                        }

                        >ul {
                            display: none;
                            // display: block $i;
                            position: absolute;
                            min-width: 243px;
                            z-index: 1;
                            padding-top: 0;
                            text-align: initial;
                            box-shadow: 3px 4px 3px 0px rgba(0, 0, 0, 0.26);
                            padding: 0;
                            top: 40px;
                            left: 0;

                            &:before {
                                content: '';
                                position: absolute;
                                right: 8%;
                                top: -12px;
                                z-index: 2;
                                background-image: #000;
                                background-size: contain;
                                width: 24px;
                                height: 12px;
                            }

                            >li {
                                display: block;
                                position: relative;
                                background: #fff;
                                line-height: 30px;
                                margin-bottom: 0px;

                                // &:not(:last-child) {
                                // 	border-bottom: 1px solid #fff;
                                // }

                                >a {
                                    padding: 6px 20px;
                                    display: block;
                                    font-size: 2rem;
                                    font-weight: 300;
                                    color: #585858;
                                    text-shadow: none $i;

                                    &:before,
                                    &:after {
                                        display: none;
                                    }

                                    &:hover {
                                        font-weight: 700 $i;
                                    }
                                }

                                ul {
                                    li {
                                        a {
                                            font-size: 1.6rem;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            >ul {
                                display: block;
                            }
                        }

                        a,
                        .nav-header {
                            font-weight: 500;
                            font-size: 1.9rem;
                            color: $primary;
                            position: relative;
                            line-height: 4rem;
                            padding: 8px 0 20px;
                            cursor: pointer;

                            &:after {
                                display: block;
                                content: attr(title);
                                font-weight: bold;
                                height: 1px;
                                color: transparent;
                                overflow: hidden;
                                visibility: hidden;
                            }
                        }

                        a:hover {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                        }

                        &.active {

                            &>a,
                            &>ul>li.active>a {
                                // font-weight: 700;

                                &:before {
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }

                        &:hover,
                        &.active {

                            >a,
                            >.nav-header {
                                color: $vividViolet;
                            }
                        }

                        &:not(.item-112) {
                            >ul {
                                padding-top: 4rem;
                                background-color: #fff;

                                >li {
                                    >a {
                                        font-size: 1.8rem;
                                        font-weight: 400 $i;
                                        color: $primary;
                                    }
                                }
                            }
                        }

                        &.mega-menu {
                            &:hover {
                                >ul {
                                    display: flex;
                                }
                            }

                            >ul {
                                left: 50%;
                                transform: translateX(-50%);
                                width: max-content;
                                padding: 4rem;
                                background-color: #fff;

                                >li {
                                    >a {
                                        color: $vividViolet;
                                        font-weight: 500 $i;
                                    }

                                    >ul {
                                        list-style: none;
                                        padding-left: 21px;

                                        a {
                                            font-size: 1.8rem;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    +div {
                        @include respond(1200) {
                            display: block $i;
                        }
                    }
                }

                @include respond(1200) {
                    order: 4;
                }
            }

            .logo {
                @include respond(1400) {
                    max-width: 150px;
                }
            }

            .search,
            .search-icon {
                @include respond(1200) {
                    display: none;
                }
            }

            .search,
            .login {
                a {
                    color: $primary;
                    font-weight: 400;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }

            .languages {
                position: absolute;
                top: 0;
                right: 5rem;

                select {
                    background-color: $primary;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    border-radius: 0;
                    border: 0;
                    text-transform: uppercase;
                }
            }
        }
    }
}