.homepage {
    #main-component {
        .container {
            .hp-slider {
                margin-top: 6.5rem;

                .slider {
                    box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.45);
                    margin-bottom: 9rem;

                    @include respond(1200) {
                        margin-bottom: 4rem;
                    }

                    .slide {
                        min-height: 46rem;
                        background-position: 100% center;
                        background-repeat: no-repeat;
                        background-color: #fff;
                        background-size: cover;

                        @include respond(1200) {
                            min-height: 38rem;
                        }

                        @include respond(992) {
                            min-height: auto;
                            background-image: none $i;
                            padding: 2rem;
                        }

                        .text-container {
                            position: absolute;
                            height: auto;
                            max-width: 52rem;
                            top: 50%;
                            left: 4rem;
                            transform: translateY(-50%);

                            @include respond(992) {
                                max-width: 100%;
                                top: auto;
                                left: auto;
                                transform: none;
                                position: relative;
                            }

                            h2 {
                                text-align: initial;
                                font-size: 4.7rem;
                                font-weight: 400;
                                color: $primary;
                                line-height: 6.1rem;
                                margin-bottom: 1rem;
                                text-transform: capitalize;
                                letter-spacing: inherit;

                                @include respond(768) {
                                    line-height: 4.1rem;
                                }
                            }

                            p {
                                font-size: 1.8rem;
                                color: #000;
                                line-height: 2.7rem;
                                margin-bottom: 4rem;
                            }

                            a {
                                color: #fff;
                                font-size: 1.8rem;
                                font-weight: 500;
                                background: linear-gradient(90deg, rgba(0, 93, 157, 1) 0%, rgba(126, 42, 144, 1) 100%);
                                padding: 10px 20px;
                            }
                        }
                    }

                    .slick-dots {
                        bottom: 2rem;

                        li {
                            width: auto;
                            height: auto;

                            button {
                                width: 1rem;
                                height: 1rem;
                                border: 1px solid #000;
                                background-color: #fff;

                                &:before {
                                    display: none;
                                }
                            }

                            &.slick-active {
                                button {
                                    border-color: transparent;
                                    background-color: $vividViolet;
                                }
                            }
                        }
                    }
                }
            }

            .hp-articles {
                margin-bottom: 10rem;

                @include respond(1200) {
                    margin-bottom: 5rem;
                }

                .items {
                    display: flex;
                    gap: 7.5rem;

                    @include respond(1200) {
                        gap: 2rem;
                    }

                    @include respond(992) {
                        flex-wrap: wrap;
                    }

                    .card {
                        margin-bottom: 0;
                        box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.45);
                        padding: 0.8rem;
                        padding-bottom: 8rem;

                        .card-img-top {
                            height: 19rem;
                            overflow: hidden;
                            margin-bottom: 1rem;

                            img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .card-body {
                            padding: 0;

                            h5 {
                                font-size: 3.7rem;
                                line-height: 6.1rem;
                                font-weight: 400;
                                color: $primary;
                            }

                            p {
                                font-size: 1.8rem;
                                font-weight: 300;
                                line-height: 3rem;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
}