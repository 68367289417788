@font-face {
    font-family: 'Kohinoor Devanagari';
    src: local('Kohinoor Devanagari Demi'), local('KohinoorDevanagari-Demi'),
        url('../fonts/KohinoorDevanagari-Demi.woff2') format('woff2'),
        url('../fonts/KohinoorDevanagari-Demi.woff') format('woff'),
        url('../fonts/KohinoorDevanagari-Demi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kohinoor Devanagari';
    src: local('Kohinoor Devanagari Book'), local('KohinoorDevanagari-Book'),
        url('../fonts/KohinoorDevanagari-Book.woff2') format('woff2'),
        url('../fonts/KohinoorDevanagari-Book.woff') format('woff'),
        url('../fonts/KohinoorDevanagari-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kohinoor Devanagari';
    src: local('Kohinoor Devanagari Bold'), local('KohinoorDevanagari-Bold'),
        url('../fonts/KohinoorDevanagari-Bold.woff2') format('woff2'),
        url('../fonts/KohinoorDevanagari-Bold.woff') format('woff'),
        url('../fonts/KohinoorDevanagari-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kohinoor Devanagari';
    src: local('Kohinoor Devanagari Light'), local('KohinoorDevanagari-Light'),
        url('../fonts/KohinoorDevanagari-Light.woff2') format('woff2'),
        url('../fonts/KohinoorDevanagari-Light.woff') format('woff'),
        url('../fonts/KohinoorDevanagari-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kohinoor Devanagari';
    src: local('Kohinoor Devanagari Medium'), local('KohinoorDevanagari-Medium'),
        url('../fonts/KohinoorDevanagari-Medium.woff2') format('woff2'),
        url('../fonts/KohinoorDevanagari-Medium.woff') format('woff'),
        url('../fonts/KohinoorDevanagari-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}