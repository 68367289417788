.news {
    #main-component {
        .container {
            .top-zone {
                max-height: 29rem;
                position: relative;
                overflow: hidden;
                margin-bottom: 8.4rem;
                margin-top: 4rem;
                box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.35);

                .image-container {
                    @include respond(768) {
                        height: 29rem;
                    }

                    img {
                        width: 100%;

                        @include respond(768) {
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }

                .text {
                    position: absolute;
                    top: 50%;
                    left: 4rem;
                    transform: translateY(-50%);
                    max-width: 59rem;

                    @include respond(768) {
                        max-width: 39rem;
                    }

                    h1 {
                        font-size: 6.8rem;
                        font-weight: 400;
                        color: #fff;

                        @include respond(992) {
                            font-size: 5.8rem;
                        }
                    }

                    p {
                        font-size: 2.3rem;
                        font-weight: 400;
                        line-height: 2.7rem;
                        color: #fff;
                    }
                }
            }

            .news-items {
                >.row {
                    margin: 0;
                    justify-content: space-between;

                    .item {
                        margin-bottom: 0;
                        box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.45);
                        padding: 0.8rem;
                        max-width: 30%;
                        margin-bottom: 8rem;
                        border-radius: 6px;

                        @include respond(992) {
                            flex: 0 48%;
                            max-width: 48%;
                        }

                        @include respond(768) {
                            flex: 0 100%;
                            max-width: 100%;
                        }

                        .item-img {
                            height: 26rem;
                            overflow: hidden;
                            margin-bottom: 1rem;

                            img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .item-body {
                            padding: 0;

                            h5 {
                                font-size: 2.4rem;
                                line-height: 3rem;
                                font-weight: 400;
                                color: $primary;
                                margin-bottom: 3rem;
                            }

                            p {
                                font-size: 1.8rem;
                                font-weight: 300;
                                line-height: 3rem;
                                color: #000;
                            }

                            .vividViolet {
                                color: $vividViolet;
                            }
                        }
                    }
                }
            }

            .our-solutions {
                h2 {
                    font-size: 5.4rem;
                    line-height: 7rem;
                    font-weight: 400;
                    color: $primary;
                    margin-bottom: 2rem;

                    +ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 5rem;
                        display: flex;
                        gap: 1rem;
                        justify-content: center;

                        li {
                            font-weight: 700;
                            font-size: 2.5rem;
                            line-height: 3.2rem;
                            color: $primary;

                            &.vividViolet {
                                color: $vividViolet;
                            }
                        }
                    }
                }

                .items {
                    padding: 0px 15px;

                    >.row {
                        &:nth-child(odd) {
                            >div {
                                background-color: #F4F5F5;
                            }
                        }

                        >div {
                            padding: 4rem 6rem;
                            text-align: center;

                            &.parent_solution_7 {
                                .item-title {
                                    color: $vividViolet;
                                }
                            }

                            &.parent_solution_8 {
                                .item-title {
                                    color: $primary;
                                }
                            }

                            .item-title {
                                font-size: 2.7rem;
                                font-weight: 400;
                                line-height: 4.2rem;
                            }

                            .item-img {
                                text-align: center;
                                margin: 1rem 0;
                            }

                            .item-text {
                                font-size: 2.1rem;
                                font-weight: 400;
                                line-height: 3.1rem;
                                color: #000;
                            }
                        }
                    }
                }

                .gdt-btn {
                    font-size: 3.1rem;
                    margin: 5rem 0;
                    display: flex;
                    width: fit-content;
                    padding: 13px 30px;
                }
            }
        }
    }
}