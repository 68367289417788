.contact-us {
    #main-component {
        .container {
            h3 {
                font-size: 3rem;
                color: $primary;
            }

            .contact {
                >.row {
                    h3 {
                        font-size: 2.8rem;
                        font-weight: 700;
                        margin-bottom: 2rem;
                    }

                    p {
                        font-size: 2.4rem;
                        font-weight: 400;
                    }

                    span {
                        position: relative;

                        &::before {
                            content: '';
                            width: 21px;
                            height: 23px;
                            background-position: center;
                            background-size: auto;
                            background-repeat: no-repeat;
                            position: relative;
                            display: inline-block;
                            vertical-align: sub;
                            margin-right: 1rem;
                            background-image: url('../images/contact_icons/email_icon.png');
                        }

                        &.location {
                            &::before {
                                background-image: url('../images/contact_icons/location_icon.png');
                            }
                        }

                        &.email {
                            &::before {
                                background-image: url('../images/contact_icons/email_icon.png');
                            }
                        }

                        &.mobile {
                            &::before {
                                background-image: url('../images/contact_icons/mobile_icon.png');
                            }
                        }

                        &.phone {
                            &::before {
                                background-image: url('../images/contact_icons/phone_icon.png');
                            }
                        }

                        &.website {
                            &::before {
                                background-image: url('../images/contact_icons/web_icon.png');
                            }
                        }
                    }
                }
            }

            .parent {
                h2 {
                    font-size: 2.9rem;
                    margin-bottom: 4rem;
                    font-weight: 700;
                }

                h3 {
                    font-size: 2.9rem;
                    margin-bottom: 2rem;
                    font-weight: 700;
                }

                h4 {
                    font-weight: 700;
                }

                p,
                h4,
                h5 {
                    font-size: 2.2rem;
                    line-height: 3rem;
                    color: #000;
                }

                p,
                h5 {
                    font-weight: 400;
                }

                p {
                    span {
                        white-space: pre-line;
                    }
                }

                h5 {
                    margin-bottom: 1rem;
                }

                &.parent_7 {
                    margin-bottom: 5rem;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        color: $vividViolet;
                    }
                }

                >.row {
                    >div {
                        margin-bottom: 6rem;

                        @include respond(992) {
                            flex: 0 50%;
                            max-width: 50%;
                        }

                        @include respond(768) {
                            flex: 0 100%;
                            max-width: 100%;
                        }

                        .inner-container {
                            border-radius: 6px;
                            background-color: #fff;
                            box-shadow: 0px 0px 12px 0px rgba(34, 34, 34, 0.43);
                            padding: 4rem 6rem;
                            height: 100%;

                            p {
                                position: relative;

                                @include respond(1200) {
                                    word-break: break-word;
                                }

                                &::before {
                                    content: '';
                                    width: 21px;
                                    height: 23px;
                                    background-position: center;
                                    background-size: auto;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    left: -30px;
                                    top: 2px;
                                }

                                &.location {
                                    &::before {
                                        background-image: url('../images/contact_icons/location_icon.png');
                                    }
                                }

                                &.email {
                                    &::before {
                                        background-image: url('../images/contact_icons/email_icon.png');
                                    }
                                }

                                &.mobile {
                                    &::before {
                                        background-image: url('../images/contact_icons/mobile_icon.png');
                                    }
                                }

                                &.phone {
                                    &::before {
                                        background-image: url('../images/contact_icons/phone_icon.png');
                                    }
                                }

                                &.website {
                                    &::before {
                                        background-image: url('../images/contact_icons/web_icon.png');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}