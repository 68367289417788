.fpd {
    #main-component {
        .container {
            .top-zone {
                max-height: 29rem;
                position: relative;
                overflow: hidden;
                margin-bottom: 5rem;
                margin-top: 4rem;
                box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.35);

                .image-container {
                    img {
                        width: 100%;
                    }
                }
            }

            .solution {
                .text {
                    h1 {
                        font-size: 5.4rem;
                        font-size: 4.7rem;
                        font-weight: 400;
                        line-height: 6.1rem;
                        border-bottom: 1px solid #669EC4;
                        padding-bottom: 2rem;
                        margin-bottom: 3rem;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 3rem;
                    }
                }

                &.solution_7,
                &.solution_175,
                &.solution_151,
                &.solution_127,
                &.solution_103 {
                    h1 {
                        color: $vividViolet;
                    }

                    .items {
                        .item-title {
                            background-color: $vividViolet;
                        }
                    }
                }

                h2 {
                    font-size: 4.7rem;
                    line-height: 6.1rem;
                    font-weight: 400;
                    text-align: inherit;
                    margin-bottom: 3rem;
                    border-bottom: 1px solid #669ec4;
                    padding-bottom: 2rem;
                }

                p {
                    font-size: 1.8rem;
                    font-weight: 400;
                    line-height: 3rem;
                    color: #000;
                }

                .items {
                    margin: 0px -25px;
                    margin-top: 4rem;
                    margin-bottom: 10rem;

                    >div {
                        margin-bottom: 6rem;
                        padding: 0px 25px;

                        .item-title {
                            border-radius: 0px 70px 0px 0px;
                            background-color: $primary;
                            margin-bottom: 0;
                            font-size: 2.3rem;
                            line-height: 2.6rem;
                            color: #fff;
                            padding: 10px 15px;
                            font-weight: 400;
                            white-space: break-spaces;
                        }

                        .item-text {
                            box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.25);
                            padding: 10px 15px;
                            font-size: 1.8rem;
                            color: #000;
                            line-height: 3rem;
                            font-weight: 400;
                        }
                    }
                }

                .gdt-btn {
                    padding: 10px 40px;
                    display: flex;
                    width: fit-content;
                    margin-bottom: 5rem;
                }
            }
        }
    }
}